import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import "@fontsource/pt-sans-narrow";
import "@fontsource/antonio/100.css"
import "@fontsource/antonio/400.css"
import "@fontsource/antonio/700.css"
import './src/styles/app.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/styles.css";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
    return (<>{element}</>)
}